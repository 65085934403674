import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReAuth } from "./baseQuery";

export const apiRoute = createApi({
    reducerPath: 'api_route',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        createPresetRoute: builder.mutation<any, any>({
            query: body => ({
                url: `api/routes/create-preset-route`,
                method: 'POST',
                body
            })
        }),
        createRoute: builder.mutation<any, any>({
            query: body => ({
                url: `api/routes/create-route`,
                method: 'POST',
                body
            })
        }),
        updateRoute: builder.mutation<any, any>({
            query: body => ({
                url: `api/routes/update-route`,
                method: 'PUT',
                body
            })
        }),
        getAllPresetRoutes: builder.mutation<any, void>({
            query: () => ({
                url: `api/routes/get-all-preset-routes`,
                method: 'GET',
            })
        }),
        getAllRoutes: builder.mutation<any, void>({
            query: () => ({
                url: `api/routes/get-all-routes`,
                method: 'GET',
            })
        }),
        getRouteById: builder.mutation<any, any>({
            query: id => ({
                url: `api/routes/route/${id}`,
                method: 'GET',
            })
        }),
        getPresetRouteById: builder.mutation<any, any>({
            query: id => ({
                url: `api/routes/preset-route/${id}`,
                method: 'GET',
            })
        }),
    }),
})

export const { useCreatePresetRouteMutation, useGetAllPresetRoutesMutation, useCreateRouteMutation, useGetAllRoutesMutation, useGetRouteByIdMutation, useUpdateRouteMutation, useGetPresetRouteByIdMutation } = apiRoute