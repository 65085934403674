import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReAuth } from "./baseQuery";

export interface ShipmentListRequest {
    status_id?: number;
};

export const shipmentsApi = createApi({
    baseQuery: baseQueryWithReAuth,
    reducerPath: 'shipments',
    endpoints: (builder) => ({
        getAllShipments: builder.mutation<any, ShipmentListRequest>({
            query: (request) => ({
                url: `api/shipments/getAll${request && request?.status_id ? `?status_id=${request.status_id}` : ''}`,
                method: 'GET',
            })
        }),
        getShipmentById: builder.mutation<any, any>({
            query: id => ({
                url: `api/shipments/${id}`,
                method: 'GET',
            })
        }),
        getAllShippingCompanies: builder.mutation<any, void>({
            query: () => ({
                url: 'api/shipments/getAllShippingCompany',
                method: 'GET',
            })
        }),
        createReception: builder.mutation<any, any>({
            query: body => ({
                url: `api/shipments/create-reception`,
                method: 'POST',
                body
            })
        }),
        createShipment: builder.mutation<any, any>({
            query: body => ({
                url: `api/shipments/create-shipment`,
                method: 'POST',
                body
            })
        }),
        updateShipmentStatus: builder.mutation<any, any>({
            query: body => ({
                url: `api/shipments/updateStatus`,
                method: 'PUT',
                body
            })
        }),
        updateShipment: builder.mutation<any, any>({
            query: body => ({
                url: `api/shipments/updateShipment`,
                method: 'PUT',
                body
            })
        }),
        getReferenceNumber: builder.mutation<any, void>({
            query: () => ({
                url: `api/shipments/get-reference-number`,
                method: 'GET'
            })
        })

    }),
});

export const {
    useGetAllShipmentsMutation,
    useGetShipmentByIdMutation,
    useGetAllShippingCompaniesMutation,
    useCreateReceptionMutation,
    useCreateShipmentMutation,
    useUpdateShipmentMutation,
    useUpdateShipmentStatusMutation,
    useGetReferenceNumberMutation
} = shipmentsApi;